<template>
  <div class="right-menu shipping-right">
    
    <ValidationObserver ref="updateShippingObserver">
      <div class="db-blue-section" v-loading.fullscreen.lock="regionLoader">

        <div class="db-container">

          <subHeader pageName="EDIT SHIPPING"/>

          <form id="update-shipping-information" @submit.prevent="validate('update')">

            <!--region basic information block starts here-->
            <div class="shipping-box">
              <div class="row">
                <div class="col-md-12">
                  <!--region name block starts here-->
                  <div class="RegionTitleBox">
                    <h3>REGION DETAIL</h3>
                    <p>Add the Destination in this Region</p>
                  </div>
                  <div class="mainEditHeading">
                    <label for="region-name">Region Name <span>*</span></label>
                    <ValidationProvider name="region name" :rules="{required:true,max:60}" v-slot="{ errors }">
                      <input 
                        type="text" 
                        id="region-name" 
                        v-model="region_name" 
                        placeholder="Region Name *"
                      >
                      <br>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!--region name block ends here-->

                  <!--destination block starts here-->
                  <div class="region-content">
                    <!--country block starts here-->
                    <div class="regionLabel">
                      <label for="countries">Countries <span>*</span></label>
                    </div>
                    <ValidationProvider name="country" rules="required" v-slot="{ errors }">
                      <Multiselect
                        v-model="selectedCountries"
                        id="countries"
                        :clearOnSelect="true"
                        :options="countriesList.length > 0 ? countriesList : []"
                        label="name"
                        :multiple="true"
                        :object="true"
                        track-by="name"
                        :hideSelected="false"
                        :showNoResults="true"
                        placeholder="Select Country"
                        :showOptions="true"
                        :searchable="true"
                        openDirection="bottom"
                        @select="countrySelection"
                        @remove="countryRemoval"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <!--country block ends here-->

                    <!--states block starts here-->
                    <template v-if="statesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="states">States <span>*</span></label>
                      </div>
                      <ValidationProvider name="states" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedStates"
                          id="states"
                          :clearOnSelect="true"
                          :options="statesGroup"
                          group-values="states" 
                          group-label="country_name" 
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          openDirection="bottom"
                          placeholer="Select States"
                          @select="stateSelection"
                          @remove="stateRemoval"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!--states block ends here-->

                    <!--cities block starts here-->
                    <template v-if="citiesGroup.length != 0">
                      <div class="regionLabel">
                        <label for="cities">Cities <span>*</span></label>
                      </div>
                      <ValidationProvider name="cities" rules="required" v-slot="{ errors }">
                        <Multiselect
                          v-model="selectedCities"
                          id="cities"
                          :clearOnSelect="true"
                          :options="citiesGroup"
                          group-values="cities" 
                          group-label="state_name"
                          :group-select="true"
                          label="name"
                          track-by="name"
                          :hideSelected="false"
                          :showNoResults="true"
                          :multiple="true"
                          :object="true"
                          :showOptions="true"
                          :searchable="true"
                          openDirection="bottom"
                          placeholer="Select Cities"
                          @select="citySelection"
                          @remove="cityRemoval"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </template>
                    <!--cities block ends here-->

                    <button type="button">Add Region</button>
                  </div>
                  <!--destination block ends here-->
                  
                </div>
              </div>
            </div>
            <!--region basic information block ends here-->

            <!--region advance information block starts here-->
            <div class="shipping-box">
              <div class="row">
                <div class="col-md-12">
                  <h2>SHIPPING CALCULATION</h2>
                  <div class="paymentForm">
                    <div class="region-content">
                      <div class="advanceChecked">
                        <input
                          type="checkbox"
                          v-model="isCODSupport"
                          name="defaultUnchecked1"
                          id="defaultUnchecked1"
                        />
                        <label for="defaultUnchecked1">Do You Support COD payment method.</label>
                      </div>
                    </div>
                    <div class="region-content" v-show="isCODSupport">
                      <div class="advanceChecked">
                        <input
                          type="checkbox"
                          v-model="isNonCODDifferent"
                          name="defaultUnchecked2"
                          id="defaultUnchecked2"
                        />
                        <label for="defaultUnchecked2">Are the charges different for COD and non-COD?</label>
                      </div>
                    </div>
                    <div class="notePargraph" v-show="isCODSupport && isNonCODDifferent">
                      <p>
                        <span>Note</span> Now you can add different prices For COD
                        on the Shipment Page.
                      </p>
                    </div>
                  </div>
                  <div class="region-content" style="margin-top: 30px">
                    <p>Select How Shipping Rates Are Calculated In This Region</p>
                  </div>
                </div>
              </div>
              <!--shipping methods block starts here-->
              <div class="row">
                <div class="col-md-6">
                  <!-- active shipping method block starts here-->
                  <div class="select-menu" v-on:click="showDropDown = !showDropDown">
                    <div class="select-img">
                      <img :src="activeShipping.img" />
                    </div>
                    <div class="free-ship-content">
                      <h3>{{ activeShipping.title }}</h3>
                      <p>{{ activeShipping.description }}</p>
                      <p v-if="activeShipping.note" style="color:red">{{ activeShipping.note }}</p>
                    </div>
                    <div class="select-menu-arrow">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                  </div>
                  <!-- active shipping method block ends here-->

                  <!-- shipping methods drop down block starts here-->
                  <div class="dropdown-calculation" v-if="showDropDown">
                    <div class="select-menu-dropdown" @click.prevent="changeOption(shipping)" v-for="shipping in shippingOptions" :key="shipping.id">
                      <div class="select-img">
                        <img :src="shipping.img" />
                      </div>
                      <div class="free-ship-content">
                        <h3>{{ shipping.title }}</h3>
                        <p>{{ shipping.description }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- shipping methods drop down block ends here-->
                </div>
              </div>

              <!-- shipping method forms start here -->
              <form action="" class="shippingDetailForm">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <!-- flat rate form starts here-->
                    <div class="flatrateBox" v-show="activeShipping.id == 5">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                        </div>
                        <ValidationProvider name="shipping option" :vid="'D-name' + item.id " :rules="activeShipping.id == 5 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`doName + ${item.id}`">Delivery Option Name <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`doName + ${item.id}`"
                              class="form-control"
                              placeholder="Delivery Option Name"
                              v-model="item.name"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div :class=" isNonCODDifferent ? 'col-md-6' : 'col-md-7'">
                            <ValidationProvider name="duration" :vid="'FR-duration' + item.id " :rules="activeShipping.id == 5 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`doDays + ${item.id}`">Delivery Days<span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`doDays + ${item.id}`"
                                  class="form-control"
                                  placeholder="e.g., 3 - 5 Delivery Days"
                                  v-model="item.business_delivery"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class=" isNonCODDifferent ? 'col-md-3': 'col-md-5'">
                            <ValidationProvider name="price" :vid="'FRCOD-price' + item.id" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FCODprice + ${item.id}`">Price <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`FCODprice + ${item.id}`"
                                  class="form-control"
                                  placeholder="Rs."
                                  v-model="item.price"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-3" v-if="isNonCODDifferent">
                            <ValidationProvider name="non code price" :vid="'FRNonCOD-price' + item.id" :rules="activeShipping.id == 5 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max:9999999} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`FNonCODprice + ${item.id}`">Price (Non-COD)<span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  class="form-control"
                                  :id="`FNonCODprice + ${item.id}`"
                                  placeholder="Rs."
                                  v-model="item.non_cod_price"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- flat rate form ends here-->

                    <!-- rate by weight form starts here-->
                    <div class="rateByWeightForm" v-show="activeShipping.id == 2">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="'name' + item.id" :rules="activeShipping.id == 2 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBW + ${item.id}`">Shipping Option <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">
                            <input
                              type="text"
                              :id="`rBW + ${item.id}`"
                              class="form-control"
                              placeholder="e.g., Standard Shipping"
                              v-model="item.name"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="'duration' + item.id" :rules="activeShipping.id == 2 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBW_DDays + ${item.id}`">Delivery Days <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBW_DDays + ${item.id}`"
                                  class="form-control"
                                  placeholder="e.g., 3 - 5 Delivery Days"
                                  v-model="item.business_delivery"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="weight_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(weight, weight_index) in item.weight" :key="weight_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label for="doName">Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="'range-from' + weight.id" :rules="activeShipping.id == 2 && weight_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value:0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="weight.from"
                                          :disabled="weight_index == 0 ? true: false"
                                          @input="inpFrom(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="'range-to' + weight.id" :rules="activeShipping.id == 2 && weight_index != item.weight.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(weight.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="weight_index == item.weight.length-1 ? 'And Upto' : 'to'"
                                          v-model="weight.to"
                                          :disabled="weight_index == item.weight.length-1 ? true: false"
                                          @input="inpTo(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="closeRangeBtn" v-if="weight_index > 1">
                                  <i class="fa fa-times" aria-hidden="true" @click="removeSubItem(item,weight,weight_index)"></i>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="'RBWCODprice' + weight.id" :rules="activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWCODprice + ${weight.id}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWCODprice + ${weight.id}`"
                                        class="form-control"
                                        placeholder="Rs."
                                        v-model="weight.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCODDifferent">
                                  <ValidationProvider name="non code price" :vid="'RBWNonCODprice' + weight.id" :rules="isNonCODDifferent && activeShipping.id == 2 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBWNonCODprice + ${weight.id}`">Price (Non-COD) <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="number"
                                        :id="`RBWNonCODprice + ${weight.id}`"
                                        class="form-control"
                                        placeholder="Rs."
                                        v-model="weight.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </div>
                                <div class="addField" v-show="weight_index == (item.weight.length - 1)">
                                  <button type="button" @click.prevent="addFields(weight_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by weight form ends here-->

                    <!-- rate by price form starts here-->
                    <div class="rateByWeightForm" v-show="activeShipping.id == 3">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <ValidationProvider name="shipping option" :vid="'name' + rule_index" :rules="activeShipping.id == 3 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                          <div class="cancelBtn">
                            <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                          </div>
                          <div class="tableFromFieldBox mb-0">
                            <label :for="`rBP_SName + ${rule_index}`">Shipping Option <span>*</span></label>
                          </div>
                          <div class="exclimatoryForm">              
                            <input
                              type="text"
                              :id="`rBP_SName + ${rule_index}`"
                              class="form-control"
                              placeholder="e.g., Standard Shipping *"
                              v-model="item.name"
                              @blur="checkDuplicateShippingOption(item)"
                            />
                            <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                            <span class="text-danger">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <div class="row">
                          <div class="col-md-4">
                            <ValidationProvider name="duration" :vid="'duration' + rule_index" :rules="activeShipping.id == 3 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`rBP_DDays + ${rule_index}`">Delivery Days <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`rBP_DDays + ${rule_index}`"
                                  class="form-control"
                                  placeholder="e.g., 3 - 5 Delivery Days"
                                  v-model="item.business_delivery"
                                />
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div :class="weight_index == 0 ? 'col-md-8':'offset-md-4 col-md-8'" v-for="(weight, weight_index) in item.prices" :key="weight_index">
                            <div class="exclimatoryForm">
                              <div class="weightRangeMainBox">
                                <div class="weightRangeCol">
                                  <div class="tableFromFieldBox mb-0">
                                    <label>Price Range <span>*</span></label>
                                  </div>
                                  <div class="weightRange2">
                                    <ValidationProvider name="from" :vid="'range-from' + weight.id" :rules="activeShipping.id == 3 && weight_index != 0 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: 0}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="from"
                                          v-model="weight.from"
                                          :disabled="weight_index == 0 ? true: false"
                                          @input="inpFrom(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider name="to" :vid="'range-to' + weight.id" :rules="activeShipping.id == 3 && weight_index != item.prices.length - 1 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,min_value: (parseFloat(weight.from) + 0.01).toFixed(2)}  : ''" v-slot="{ errors }">
                                      <div class="rangeEnterValue">
                                        <input
                                          type="number"
                                          class="form-control"
                                          :placeholder="weight_index == item.prices.length-1 ? 'And Upto' : 'to'"
                                          v-model="weight.to"
                                          :disabled="weight_index == item.prices.length-1 ? true: false"
                                          @input="inpTo(weight_index, rule_index)"
                                        />
                                      </div>
                                      <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="closeRangeBtn" v-if="weight_index > 1">
                                <i class="fa fa-times" aria-hidden="true" @click="removeSubItem(item,weight,weight_index)"></i>
                                </div>
                                <div class="weightRange3">
                                  <ValidationProvider name="price" :vid="'RBPprice' + weight.id" :rules="activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBRprice + ${weight.id}`">Price <span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBRprice + ${weight.id}`"
                                        class="form-control"
                                        placeholder="Rs."
                                        v-model="weight.price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="weightRange3" v-if="isNonCODDifferent">
                                  <ValidationProvider name="price" :vid="'RBPNONCODprice' + weight.id" :rules="isNonCODDifferent && activeShipping.id == 3 ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/,max_value:9999999} : ''" v-slot="{ errors }">
                                    <div class="tableFromFieldBox mb-0">
                                      <label :for="`RBPNONCODprice + ${weight.id}`">Price (Non-COD)<span>*</span></label>
                                    </div>
                                    <div class="exclimatoryForm">
                                      <input
                                        type="text"
                                        :id="`RBPNONCODprice + ${weight.id}`"
                                        class="form-control"
                                        placeholder="Rs."
                                        v-model="weight.non_cod_price"
                                      />
                                      <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                    </div>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                  </ValidationProvider>
                                </div>
                                <div class="addField" v-show="weight_index == (item.prices.length - 1)">
                                  <button type="button" @click.prevent="addFields(weight_index, rule_index)"><span>+</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Shipping Option</button>
                      </div>
                    </div>
                    <!-- rate by price form ends here-->

                    <!-- store pickup only form starts here -->
                    <div class="storePickUpForm" v-show="activeShipping.id == 4">
                      <div v-for="(item, rule_index) in shippingRules" :key="rule_index">
                        <hr v-show="rule_index > 0" />
                        <div class="cancelBtn">
                          <i class="fa fa-times" aria-hidden="true" @click="removeItem(item,rule_index)"></i>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <ValidationProvider name="store name" :rules="activeShipping.id == 4 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sName + ${rule_index}`">Store Name <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sName + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store Name"
                                  v-model="item.name"
                                  @blur="checkDuplicateShippingOption(item)"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store number" :rules="activeShipping.id == 4 ? {required:true,max:25} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sPhoneNo + ${rule_index}`">Store Phone No.<span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="number"
                                  :id="`sPhoneNo + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store Number"
                                  v-model="item.number"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store location" :rules="activeShipping.id == 4 ? {required:true,regex:/^\S.*\S$/,max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sLocation + ${rule_index}`">Store Location <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sLocation + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store Location"
                                  v-model="item.address"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                          <div class="col-md-6">
                            <ValidationProvider name="store city" :rules="activeShipping.id == 4 ? {required:true,regex:'^[a-zA-Z]+( [a-zA-Z]+)*$',max:250} : ''" v-slot="{ errors }">
                              <div class="tableFromFieldBox mb-0">
                                <label :for="`sCity + ${rule_index}`">Store City <span>*</span></label>
                              </div>
                              <div class="exclimatoryForm">
                                <input
                                  type="text"
                                  :id="`sCity + ${rule_index}`"
                                  class="form-control"
                                  placeholder="Store City"
                                  v-model="item.city"
                                />
                                <img src="../../../assets/images/exclimatory-mark.png" alt=""/>
                                <span class="text-danger">{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                      <div class="addMoreForm">
                        <button type="button" @click.prevent="addMoreForm">+ Add Another Store details</button>
                      </div>
                    </div>
                    <!-- store pickup only form ends here -->
                  </div>
                </div>
              </form>
              <!-- shipping method forms ends here -->

              <!--shipping methods block ends here-->
            </div>
            <!--region advance information block ends here-->

            <!--region advance settings block starts here-->
            <div class="shipping-box settingBox">
              <div class="row">
                <div class="col-md-12">
                  <h2>ADVANCE SETTINGS</h2>
                  <!-- handling fee block starts here-->
                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked5"
                        id="defaultUnchecked5"
                        v-model="handlingFee.isActive"
                      />
                      <label for="defaultUnchecked5">Add A Handling Fee To Every Order</label>
                    </div>
                    <!-- <ValidationProvider name="handling fee" :rules="handlingFee.isActive ? {required:true,regex:/^(?:\d*\.\d{1,2}|\d+)$/} : ''" v-slot="{ errors }">
                      <div class="advanceChecked" v-if="handlingFee.isActive">
                        <input
                          type="text"
                          name="handlingfee"
                          id="handlingfee"
                          placeholder="Add Handling Fee"
                          v-model="handlingFee.fee"
                        />
                      </div>
                      <span class="text-danger" v-if="handlingFee.isActive && errors[0]">{{ errors[0] }}</span>
                    </ValidationProvider> -->
                  </div>
                  <!-- handling fee block ends here-->

                  <div class="region-content">
                    <div class="advanceChecked">
                      <input
                        type="checkbox"
                        name="defaultUnchecked6"
                        id="defaultUnchecked6"
                        required
                      />
                      <label for="defaultUnchecked6">Allow Store Pickup</label>
                    </div>
                  </div>
                  <div class="settingInfoParagraph">
                    <p>
                      Let Customers Pick Up Orders In Person As One Of The Delivery
                      Options In This Region.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!--region advance settings block ends here-->

            <!-- save shipping block starts here -->
            <div class="shippingBtn">
              <button type="button" @click.prevent="goBack()">CANCEL</button>
              <loaderBtn v-if="editShippingLoader"/>
              <button
                form="update-shipping-information" 
                button="submit" 
                @click.prevent="validate('update')" 
                v-else
              >
                SAVE
              </button>
            </div>
            <!-- save shipping block ends here -->

          </form>

        </div>
      </div>
     </ValidationObserver>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import _ from "lodash";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Swal from 'sweetalert2';
import subHeader from "@/components/subHeader.vue";
import { Message, MessageBox } from "element-ui";
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from "vuex";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo() {
    return {
      title: 'E-Commerce | Edit Shipping Region | YeetCommerce',
    };
  },
  data: () => ({
    region_name:'',
    temp_region_name:'',
    region_id:'',
    isCODSupport: true,
    isNonCODDifferent: false,
    showDropDown: false,
    handlingFee:{
      isActive:false,
      fee:''
    },
    countriesList:[],
    statesList:[],
    citiesList:[],
    statesGroup:[],
    citiesGroup:[],
    orgCountries:[],
    orgStates:[],
    orgCities:[],
    selectedCountries: [],
    selectedStates: [],
    selectedCities: [],
    activeShipping: {
      id: 1,
      title: "Free Shipping",
      description: "Offer Free Shipping To Your Customers",
      img: "/images/free-shipping.png",
    },
    shippingOptions: [
      {
        id: 2,
        title: "Rate By Weight",
        description:"Calculate Rate Based On The Total Weight Of Customer's Order",
        note:"Rate will automatically set to 0 if any of the products available in the cart have 0 weight",
        img: "/images/balance@2x.png",
      },
      {
        id: 3,
        title: "Rate By Price",
        description:"Calculate Rate Based On The Total Weight Of A Customer's Order",
        img: "/images/cart@2x.png",
      },
      {
        id: 4,
        title: "Store Pickup Only",
        description: "Only Allow Custpomers To Pickup Their Orders In Person",
        img: "/images/front-store@2x.png",
      },
      {
        id: 5,
        title: "Flat Rate",
        description: "Apply The Same Rate For Customer's Entire Order",
        img: "/images/flat-rate@2x.png",
      },
    ],
    shippingRules: [],
    rule: null,
    editShippingLoader:false,
    isTouched:false,
    isFound:false,
    regionLoader:false,
  }),
  components: {
    Multiselect,
    subHeader,
    loaderBtn
  },
  methods: {
    async countrySelection(item,i){
      
      if(this.orgCountries.some(country => country.id == item.id)){

        if(this.selectedStates.length > 0 ){

          if(this.statesList.length > 0){

            let countryIds = []

            this.statesGroup = []

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);

                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj)

              });

              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj)

              }


            }else{
              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){
                this.statesGroup.push(obj)
              }
            }

            let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
            if(res.data.status_code == "1010"){

              this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)))

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  this.statesList.forEach(state =>{

                    if(state.country_id == group.country_id){

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state)

                      }

                    }

                  })

                })

              }

            }
          }else{

            this.statesList = this.selectedStates

            this.statesGroup = []

            this.selectedCountries.forEach(country => {

              let obj = {
                country_id:country.id,
                country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              this.statesGroup.push(obj)

            });

            let obj = {
              country_id:item.id,
              country_name: item.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            if(this.statesGroup.some(groupObj => groupObj == obj)){

              this.statesGroup.push(obj)

            }

            if(this.statesGroup.length > 0){

              this.statesGroup.forEach(group => {

                this.statesList.forEach(state =>{

                  if(state.country_id == group.country_id){

                    group.states.push(state)

                  }

                })

              })

            }
              
          }
        }else{

          if(this.statesList.length > 0){

            let countryIds = []

            this.statesGroup = []

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);
                
                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj)

              });

              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj)

              }

            }else{

              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              this.statesGroup.push(obj)

            }

            let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
            if(res.data.status_code == "1010"){

              this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)))

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  this.statesList.forEach(state =>{

                    if(state.country_id == group.country_id){

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state)

                      }

                    }

                  })

                })

              }
            
            }
          }else{

            let countryIds = []

            this.statesGroup = []

            if(this.selectedCountries.length > 0){

              this.selectedCountries.forEach(country => {

                countryIds.push(country.id);

                let obj = {
                  country_id:country.id,
                  country_name: country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj)

              });

              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name,
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj)

              }

            }else{

              countryIds.push(item.id)

              let obj = {
                country_id:item.id,
                country_name: item.name + " (Selecting this option will select all the states available in this country.)",
                states:[]
              }

              if(this.statesGroup.some(groupObj => groupObj == obj)){

                this.statesGroup.push(obj)

              }

            }

            let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
            if(res.data.status_code == "1010"){
            
              this.statesList = res.data.states.concat(this.orgStates.filter(obj2 => !res.data.states.some(obj1 => obj1.id === obj2.id)))

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  this.statesList.forEach(state =>{

                    if(state.country_id == group.country_id){

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state)

                      }

                    }

                  })

                })

              }

            }
          }
        }

        // if(this.selectedCities.length > 0){
        //   this.selectedCities = [...this.selectedCities,...this.orgCities.filter(city => city.country_id == item.id)]

        //   this.citiesList = this.selectedCities
        // }else{

        //   if(this.citiesList.length > 0){
        //     this.citiesList = [...this.statesList,...this.orgCities.filter(city => city.country_id == item.id)]

        //     this.selectedCities = this.citiesList
        //   }else{
        //     this.selectedCities = this.orgCities.filter(city => city.country_id == item.id)

        //     this.citiesList = this.selectedCities
        //   }
        // }
        
      }else{

        let countryIds = []

        this.statesGroup = []

        if(this.selectedCountries.length > 0){

          this.selectedCountries.forEach(country => {

            countryIds.push(country.id);

            let obj = {
              country_id:country.id,
              country_name: country.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            this.statesGroup.push(obj)

          });

          countryIds.push(item.id)

          let obj = {
              country_id:item.id,
              country_name: item.name + " (Selecting this option will select all the states available in this country.)",
              states:[]
            }

            if(this.statesGroup.some(groupObj => groupObj == obj)){

              this.statesGroup.push(obj)

            }

        }else{

          countryIds.push(item.id)

          let obj = {
            country_id:item.id,
            country_name: item.name + " (Selecting this option will select all the states available in this country.)",
            states:[]
          }

          if(this.statesGroup.some(groupObj => groupObj == obj)){

            this.statesGroup.push(obj)

          }

        }

        let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}})
        if(res.data.status_code == "1010"){
          
          if(this.selectedStates.length > 0){
            
            if(this.statesList.length > 0){

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  res.data.states.forEach(state =>{

                    if(state.country_id == group.country_id){

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state)

                      }

                    }

                  })

                })

              }

            }else{

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  res.data.states.forEach(state =>{

                    if(state.country_id == group.country_id){

                      if(!group.states.some((groupedState) => groupedState.name == state.name)){

                        group.states.push(state)

                      }

                    }

                  })
                  
                })

              }

            }

          }else{

            if(this.statesList.length > 0){

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  res.data.states.forEach(state =>{

                    if(!group.states.some((groupedState) => groupedState.name == state.name)){

                      group.states.push(state)
                      
                    }

                  })

                })

              }

            }else{

              if(this.statesGroup.length > 0){

                this.statesGroup.forEach(group => {

                  res.data.states.forEach(state =>{

                    if(!group.states.some((groupedState) => groupedState.name == state.name)){

                      group.states.push(state)

                    }

                  })

                })

              }

            }

          }

          // let stateIds = [];
          
          // this.selectedStates.forEach(state => {
          //   stateIds.push(state.id);
          // });
          
          // let cityRes = await this.$axios.get('/state/cities/all',{params:{states:stateIds}})
          // if(cityRes.data.status_code == "1010"){

          //   if(this.citiesList.length > 0){
          //     this.citiesList = [...this.citiesList,...cityRes.data.cities];

          //     this.selectedCities = this.citiesList
          //   }else{
          //     this.citiesList = cityRes.data.cities

          //     this.selectedCities = cityRes.data.cities
          //   }

          // }

        }
      }
    },
    async countryRemoval(item,i){

      this.selectedStates = this.selectedStates.filter((state)=>{

        if(state.country_id != item.id){

          return state

        }

      });

      this.statesList = this.statesList.filter((state)=>{

        if(state.country_id != item.id){

          return state

        }

      })

      this.statesGroup = this.statesGroup.filter(group =>{

        if(group.country_id != item.id){

          return group

        }

      })

      this.selectedCities = this.selectedCities.filter((city) => {

        if(city.country_id != item.id){

          return city

        }

      });

      this.citiesList = this.selectedCities

      this.citiesGroup = []

      this.selectedStates.forEach(state => {

        let obj = {
          state_id:state.id,
          state_name: state.name + " (Selecting this option will select all the states available in this country.)",
          cities:[]
        }

        this.citiesGroup.push(obj)

      });

      this.citiesGroup.forEach(group => {

        this.citiesList.forEach(city =>{

          if(city.state_id == group.state_id){

            group.cities.push(city)

          }

        })

      })

    },
    async stateSelection(item,i){

      if(this.orgStates.some(state => state.id == item.id)){

        if(this.selectedCities.length > 0){

          this.citiesList = [...this.selectedCities,...this.orgCities.filter(city => city.state_id == item.id)]

          this.citiesGroup = [];

          let stateIds = [];

          this.selectedStates.forEach(state => {

            stateIds.push(state.id);

            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj)

          });

          let obj = {
            state_id:item.id,
            state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
            cities:[]
          }

          if(this.citiesGroup.some(groupObj => groupObj == obj)){

            this.citiesGroup.push(obj)

          }

          this.citiesGroup.forEach(group => {

            this.citiesList.forEach(city =>{

              if(city.state_id == group.state_id){

                if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                  group.cities.push(city)

                }

              }

            })

          })

        }else{

          if(this.citiesList.length > 0){

            this.citiesList = [...this.citiesList,...this.orgCities.filter(city => city.state_id == item.id)]

            this.citiesGroup = [];

            let stateIds = [];

            this.selectedStates.forEach(state => {
              stateIds.push(state.id);

              let obj = {
                state_id:state.id,
                state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                cities:[]
              }

              this.citiesGroup.push(obj)

            });

            let obj = {
              state_id:item.id,
              state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            if(this.citiesGroup.some(groupObj => groupObj == obj)){

              this.citiesGroup.push(obj)

            }

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){
                    
                    group.cities.push(city)

                  }

                }

              })

            })

          }else{

            this.citiesList = this.orgCities.filter(city => city.state_id == item.id)

            this.citiesGroup = [];

            let stateIds = [];

            this.selectedStates.forEach(state => {
              stateIds.push(state.id);

              let obj = {
                state_id:state.id,
                state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                cities:[]
              }

              this.citiesGroup.push(obj)

            });

            let obj = {
              state_id:item.id,
              state_name: item.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            if(this.citiesGroup.some(groupObj => groupObj == obj)){

              this.citiesGroup.push(obj)
              
            }

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                    group.cities.push(city)

                  }

                }

              })

            })

            let cityRes = await this.$axios.get('/state/cities/all',{params:{states:stateIds}})
            if(cityRes.data.status_code == "1010"){

              if(this.citiesList.length > 0){

                this.citiesList = [...this.citiesList,...cityRes.data.cities.filter(obj2 => !this.citiesList.some(obj1 => obj1.id === obj2.id))];

                this.citiesList = this.citiesList.filter((city) =>{

                  if(stateIds.some(id => id == city.state_id))

                  return city

                })

                this.citiesGroup.forEach(group => {

                  this.citiesList.forEach(city =>{

                    if(city.state_id == group.state_id){

                      if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){
                        
                        group.cities.push(city)

                      }

                    }

                  })

                })

              }

            }

          }

        }

      }else{

        let stateIds = [];

        this.citiesGroup = [];
          
        this.selectedStates.forEach(state => {
          stateIds.push(state.id);

          let obj = {
            state_id:state.id,
            state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
            cities:[]
          }

          this.citiesGroup.push(obj)

        });

        if(item.length > 1){

          item.forEach(state =>{

            stateIds.push(state.id)
            
            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj)
            
          })

        }else{

          stateIds.push(item.id)

          let obj = {
            state_id:item.id,
            state_name: item.name,
            cities:[]
          }
          
          if(this.citiesGroup.some(groupObj => groupObj == obj)){

            this.citiesGroup.push(obj)

          }

        }

        let cityRes = await this.$axios.get('/state/cities/all',{params:{states:stateIds}})
        if(cityRes.data.status_code == "1010"){

          if(this.citiesList.length > 0){

            this.citiesList = [...this.citiesList,...cityRes.data.cities.filter(obj2 => !this.citiesList.some(obj1 => obj1.id === obj2.id))];

            this.citiesList = this.citiesList.filter((city) =>{
              if(stateIds.some(id => id == city.state_id))
              return city
            })

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                    group.cities.push(city)

                  }

                }

              })

            })

          }else{

            this.citiesList = cityRes.data.cities

            this.citiesGroup = [];

            this.selectedStates.forEach(state => {
              stateIds.push(state.id);

              let obj = {
                state_id:state.id,
                state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                cities:[]
              }

              this.citiesGroup.push(obj)

            });

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                    group.cities.push(city)

                  }

                }

              })
              
            })

          }

        }

      }

    },
    async stateRemoval(item,i){

      if(item.length > 1){

        this.selectedCities = this.selectedCities.filter((city) => {

          if(!item.some(state => state.id == city.state_id)){

            return city

          }

        })

        this.citiesList = this.citiesList.filter((city)=>{

          if(!item.some(state => state.id == city.state_id)){

            return city

          }

        })

        this.citiesGroup = []

        this.selectedStates.forEach(selectedState => {

          if(!item.some(state => state.id == selectedState.id)){

            let obj = {
              state_id:selectedState.id,
              state_name: selectedState.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj)
          }

        });

        this.citiesGroup.forEach(group => {

          this.citiesList.forEach(city =>{

            if(city.state_id == group.state_id){

              group.cities.push(city)

            }

          })

        })

      }else{

        this.selectedCities = this.selectedCities.filter((city) => {

          if(city.state_id != item.id){

            return city

          }

        })

        this.citiesList = this.citiesList.filter((city)=>{

          if(city.state_id != item.id){

            return city

          }

        })

        this.citiesGroup = []

        this.selectedStates.forEach(state => {

          if(state.id != item.id){

            let obj = {
              state_id:state.id,
              state_name: state.name + " (Selecting this option will select all the cities/districts available in this state.)",
              cities:[]
            }

            this.citiesGroup.push(obj)

          }

        });

        this.citiesGroup.forEach(group => {

          this.citiesList.forEach(city =>{

            if(city.state_id == group.state_id){

              group.cities.push(city)

            }

          })

        })

      }

    },
    async citySelection(item,i){

      let state = this.statesList.find(state => state.id == item.state_id);

      if(state){

        if(!this.selectedStates.some(selectedState => selectedState.id == state.id)){

          this.selectedStates.push(state)

        }

      }

    },
    async cityRemoval(item,i){

      if(this.selectedCities.length == 1){
        // this.selectedStates = this.selectedStates.filter((state)=>{
        //   if(state.id != item.state_id){
        //     return state
        //   }
        // })
      }

    },
    changeOption(option){

      let current = this.activeShipping;

      this.shippingOptions = this.shippingOptions.map((element) => {

        if(element.id == option.id){

          return current;

        }else{

          return element;

        }

      });

      this.activeShipping = option;
      this.showDropDown = false;

      if(this.activeShipping.title == "Rate By Weight"){

        Swal.fire({
          title: 'Note',
          text: this.activeShipping.note,
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: '#15223D',
          cancelButtonColor: '#15223D',
          confirmButtonText: 'OK'
        }).then((result) => {

          if(result.isConfirmed){
            
          }

        })

      }

    },
    removeItem(item,index){

      MessageBox.confirm(
        "Are you sure ? The action is irreversible.", 
        "Warning", 
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(()=>{

        if(this.shippingRules.length > 1){

          this.shippingRules.splice(index,1);

        }else{

          this.shippingRules = []
          this.addMoreForm()

        }
      }).catch(()=>{})

    },
    removeSubItem(item,subItem,subItemIndex){

      if(!this.tourIsActive){

        MessageBox.confirm(
          "Are you sure ? The action is irreversible.", 
          "Warning", 
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(()=>{

          if(this.activeShipping.id == 2){

            if(item.weight.length > 2){

              item.weight.splice(subItemIndex,1);

              for( let i = 0; i < item.weight.length; i++){

                if(i + 1 == item.weight.length){
                
                  item.weight[i].from = parseFloat(parseFloat(item.weight[i - 1].to)  + 0.01).toFixed(2)
                  item.weight[i].to = ""

                }else if(i != 0){
                  
                  item.weight[i].from = parseFloat(parseFloat(item.weight[i - 1].to)  + 0.01).toFixed(2)
                  item.weight[i].to = parseFloat(parseFloat(item.weight[i].from)  + 0.01).toFixed(2)
                
                }

              }

            }

          }else if(this.activeShipping.id == 3){

            if(item.prices.length > 2){

              item.prices.splice(subItemIndex,1);

              for( let i = 0; i < item.prices.length; i++){

                if(i + 1 == item.prices.length){
                
                  item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2)
                  item.prices[i].to = ""

                }else if(i != 0){
                  
                  item.prices[i].from = parseFloat(parseFloat(item.prices[i - 1].to)  + 0.01).toFixed(2)
                  item.prices[i].to = parseFloat(parseFloat(item.prices[i].from)  + 0.01).toFixed(2)
                
                }

              }

            }

          }else if(this.activeShipping.id == 4){

          }else if(this.activeShipping.id == 5){

          }
        }).catch(()=>{

        })
      }

    },
    addFields(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(parseFloat(this.shippingRules[rule_index].weight[child_index - 1].to) > 0){

          let obj = {
          id: uuidv4(),
          from: parseFloat(parseFloat(this.shippingRules[rule_index].weight[child_index].from)  + 0.01).toFixed(2),
          to: '',
          price: '',
          non_cod_price:'',
        }
        this.shippingRules[rule_index].weight[child_index].to = obj.from
        this.shippingRules[rule_index].weight.push(obj)
        this.shippingRules[rule_index].weight[this.shippingRules[rule_index].weight.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2)

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required.",
          });

        }

      }else if(this.activeShipping.id == 3){

        if(parseFloat(this.shippingRules[rule_index].prices[child_index - 1].to) > 0){

          let obj = {
            id: uuidv4(),
            from: parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) + 0.01).toFixed(2),
            to: '',
            price: '',
            non_cod_price:'',
          }
          this.shippingRules[rule_index].prices[child_index].to = obj.from
          this.shippingRules[rule_index].prices.push(obj)

          this.shippingRules[rule_index].prices[this.shippingRules[rule_index].prices.length - 1].from = (parseFloat(obj.from) + 0.01).toFixed(2)

        }else{

          this.$notify({
            type: "warning",
            title: "Range Required",
            message: "Fields of previous range are required",
          });

        }

      }

    },
    inpFrom(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weight[child_index-1]){

          this.shippingRules[rule_index].weight[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].weight[child_index].from) - 0.01).toFixed(2)
        
        }

      }else if(this.activeShipping.id == 3){

        if(this.shippingRules[rule_index].prices[child_index-1]){

          this.shippingRules[rule_index].prices[child_index-1].to = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].from) - 0.01).toFixed(2)
        
        }

      }

    },
    inpTo(child_index, rule_index){

      if(this.activeShipping.id == 2){

        if(this.shippingRules[rule_index].weight[child_index+1]){

          this.shippingRules[rule_index].weight[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].weight[child_index].to) + 0.01).toFixed(2)
        
        }

      }else if(this.activeShipping.id == 3){
        
        if(this.shippingRules[rule_index].prices[child_index+1]){
         
          this.shippingRules[rule_index].prices[child_index+1].from = parseFloat(parseFloat(this.shippingRules[rule_index].prices[child_index].to) + 0.01).toFixed(2)
        
        }

      }

    },
    addMoreForm(){

      let newRule = {}

      if(this.activeShipping.id == 2){

        newRule = {
          id: uuidv4(),
          name: "",
          business_delivery: "",
          weight: [
            {
              id: uuidv4(),
              from: 0.0,
              to: 1,
              price: "",
              non_cod_price: "",
            },
            {
              id: uuidv4(),
              from: 1,
              to: '',
              price: "",
              non_cod_price: "",
            },
          ],
        };

      }else if(this.activeShipping.id == 3){

        newRule = {
          id: uuidv4(),
          name: "",
          business_delivery: "",
          prices: [
            {
              id: uuidv4(),
              from: 0.0,
              to: 1,
              price: "",
              non_cod_price: "",
            },
            {
              id: uuidv4(),
              from: 1,
              to: '',
              price: "",
              non_cod_price: "",
            },
          ],
        };

      }else if(this.activeShipping.id == 4){

        newRule = {
          id: uuidv4(),
          name: "",
          number:'',
          address: '',
          city: ''
        };

      }else if(this.activeShipping.id == 5){

        newRule = {
          id: uuidv4(),
          name: "",
          business_delivery:'',
          price: '',
          non_cod_price:'',
        };

      }

      this.shippingRules.push(_.cloneDeep(newRule));

    },
    async validate(action){
      
      if(action == "update"){

        this.$refs.updateShippingObserver.validate().then(success => {

          if(!success){

            const errors = Object.entries(this.$refs.updateShippingObserver.errors)
            .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

            this.$refs.updateShippingObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

          }else{

            this.updateRegion();

          }

        });

      }
    },
    checkDuplicateShippingOption(item){

      if(this.activeShipping.id == 2){
        //rate by weight
        
        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }else if(this.activeShipping.id == 3){
        //rate by price
        
        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }else if(this.activeShipping.id == 4){
        //store pickup only
        
        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Store Name Already Exists',
              message: "A store already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }else if(this.activeShipping.id == 5){
        //flat rate

        item.name = item.name.trim()

        if(item.name != ''){

          let count = 0;

          for(let i = 0; i < this.shippingRules.length; i++){

            if(this.shippingRules[i].name == item.name){

              count++;

            }

          }

          if(count > 1){

            this.$notify({
              type: 'error',
              title: 'Option Name Already Exists',
              message: "An option already exists with the entered name. Kindly enter a unique one.",
            });

            item.name = ''

          }

        }

      }

    },
    async updateRegion(){
      
      let form = {
        id: this.$route.params.id,
        cities: this.selectedCities,
        name: this.region_name.trim(),
        shipping: {
          ...this.activeShipping,
          rules: this.shippingRules
        },
        is_cod_supported: this.isCODSupport ? 1 : 0,
        is_non_cod_different: this.isNonCODDifferent ? 1 : 0,
      }

      if(this.handlingFee.fee && this.handlingFee.isActive){
        form.handling_fee = this.handlingFee.fee
      }

      this.editShippingLoader = true
      try{
        let res = await this.$axios.post('/updateRegion', form);
        if(res.data.status_code == '1014'){
            
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let region = res.data.region;

          region.shipping.rules = JSON.parse(region.shipping.rules);
          
          this.$store.commit('shipping_module/update_regions_list',region);
          this.$router.push('/shipping');
        }
      }catch(error){
        
        if(error.response){

          if(error.response.data.error.name){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.error.name[0],
            });

          }else if(error.response.data.id){

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.id[0],
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.editShippingLoader = false

      }
      
    },
    updateRules(rules){

      setTimeout(()=>{

        this.shippingRules = rules

      });

    },
    goBack(){

      if(this.selectedCities || this.shippingRules.length > 0 || this.isTouched){

        this.isGoBack = true
        this.$router.push('/shipping');

      }else{

        this.isGoBack = true
        this.$router.push('/shipping');

      }
      
    },
    async loadStates(){

      let countryIds = [];

      this.selectedCountries.forEach(country => {
        countryIds.push(country.id);
      });

      try{
        let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}});
        if(res.data.status_code == "1010"){

          if(this.selectedStates){
            
            this.selectedStates = this.selectedStates.filter((state)=>{
              if(countryIds.includes(state.country_id)){
                return state
              }
            })

            this.statesList = [...this.selectedStates,...res.data.states]

          }else{
            this.statesList = res.data.states
          }

          this.selectedStates = this.statesList

        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

    },
    async loadCities(){

      let stateIds = [];

      this.selectedStates.forEach(state => {
        stateIds.push(state.id);
      });

      try{
        let res = await this.$axios.get('/state/cities/all',{params:{states:stateIds}});
        if(res.data.status_code == "1010"){
          
          if(this.selectedCities){

            if(stateIds.length > 0){

              this.selectedCities = this.selectedCities.filter((city)=>{

                if(stateIds.includes(city.state_id)){
                  
                  return city

                }

              });

            }
            
            this.citiesList = [...this.selectedCities,...res.data.cities];

          }else{
            
            this.citiesList = res.data.cities

          }

          this.selectedCities = this.citiesList
        }
      }catch(error){

        this.$message({
          type: "error",
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

    }
  },
  async beforeMount(){

    try{

      if(this.$route.params.id){

        this.regionLoader = true
        try{
          let response = await this.$axios.get(`/getRegion/${this.$route.params.id}`)
          if(response.data.status_code == '1013'){
            this.regionLoader = false

            let region = response.data.region;
            region.shipping.rules = JSON.parse(region.shipping.rules);

            this.isCODSupport = region.shipping.isCODSupport
            this.isNonCODDifferent = region.shipping.isNonCODDifferent

            if(region.shipping.handling_fee){

              this.handlingFee.isActive = 1
              this.handlingFee.fee = region.shipping.handling_fee

            }

            this.region_name = region.name

            this.temp_region_name = region.name

            this.region_id = this.$route.params.id

            let countries = [];
            let states = [];
            let cities = [];

            region.related_cities.forEach((relatedCity)=>{

              if(!countries.some(country => country.id == relatedCity.city.country.id)){
                
                countries.push(relatedCity.city.country)

                let obj = {
                  country_id:relatedCity.city.country.id,
                  country_name: relatedCity.city.country.name + " (Selecting this option will select all the states available in this country.)",
                  states:[]
                }

                this.statesGroup.push(obj)

              }

              if(!states.some(state => state.id == relatedCity.city.state.id)){

                states.push(relatedCity.city.state)

                let obj = {
                  state_id:relatedCity.city.state.id,
                  state_name: relatedCity.city.state.name + " (Selecting this option will select all the cities/districts available in this state.)",
                  cities:[]
                }

                this.citiesGroup.push(obj)

              }
              if(!cities.some(city => city.id == relatedCity.city.id)){

                let obj = {
                  id: relatedCity.city.id,
                  name: relatedCity.city.name,
                  state_id: relatedCity.city.state_id,
                  state_code: relatedCity.city.state_code,
                  country_id: relatedCity.city.country_id,
                  country_code: relatedCity.city.country_code,
                  latitude: relatedCity.city.latitude,
                  longitude: relatedCity.city.longitude,
                  wikiDataId: relatedCity.city.wikiDataId,
                }

                cities.push(obj)

              }

            });

            this.orgCountries = countries
            this.selectedCountries = countries

            this.selectedStates = states;
            this.statesList = states
            this.orgStates = states
            // this.statesGroup[0].states = states

            this.selectedCities = cities;
            this.citiesList = cities;
            this.orgCities = cities;
            // this.citiesGroup[0].cities = cities

            this.citiesGroup.forEach(group => {

              this.citiesList.forEach(city =>{

                if(city.state_id == group.state_id){

                  group.cities.push(city)

                }

              });

            });

            this.shippingOptions.forEach(option=>{

              if(region.shipping.type == option.title){

                this.changeOption(option)

                this.shippingRules = region.shipping.rules;

                this.updateRules(region.shipping.rules)

              }

            });

          }
        }catch(error){

          this.regionLoader = false

          this.$router.push('/shipping')

          if(error.response){

            if(error.response.data.error.includes("deleted or isn't available")){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

          return

        }

        if(!this.storeCountries || this.storeCountries.length == 0){

          this.$store.dispatch('countries_module/fetchStoreCountriesRequest')

        }else{

          this.countriesList = this.storeCountries

          this.countriesList.push(this.selectedCountries)

        }

        let countryIds = [];

        this.selectedCountries.forEach(country => {

          countryIds.push(country.id);

        });

        let res = await this.$axios.get('/country/states/all',{params:{countries:countryIds}});
        if(res.data.status_code == "1010"){
          
          if(this.statesList.length > 0){

            let uniqueStates = res.data.states.filter((state) => {

              if(!this.statesList.some((listedState) => listedState.name == state.name)){

                return state

              }

            });

            this.statesList = [...this.statesList,...uniqueStates]

            // this.statesGroup[0].states = [...this.statesGroup[0].states,...res.data.states]

            if(this.statesGroup.length > 0){

              this.statesGroup.forEach(group => {

                this.statesList.forEach(state =>{

                  if(state.country_id == group.country_id){

                    group.states.push(state)

                  }

                });

              });

            }

          }else{

            this.statesList = res.data.states

          }

          let stateIds = [];

          this.statesList.forEach(state => {

            stateIds.push(state.id);

          });


          let resCities = await this.$axios.get('/state/cities/all',{params:{states:stateIds}});
          if(resCities.data.status_code == "1010"){

            if(this.citiesList.length > 0){

              this.citiesList = [...this.citiesList,...resCities.data.cities];

              this.citiesGroup.forEach(group => {

                this.citiesList.forEach(city =>{

                  if(city.state_id == group.state_id){

                    if(!group.cities.some((groupedCity) => groupedCity.name == city.name)){

                      group.cities.push(city)

                    }

                  }

                });

              });

            }else{

              this.citiesList = resCities.data.cities;

            }

          }

        }

      }
    }catch(error){

      this.regionLoader = false

      if(error.response){

        if(error.response.data.error.includes("deleted or isn't available")){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.error,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }

      }else{

        this.$message({
          type: 'error',
          showClose: true,
          message: error.message,
        });

      }

    }
    this.editShippingLoader = false
    
  },
  mounted(){

  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      storeCountries:'countries_module/countries',
    })
  },
  watch: {
    storeCountries:{
      handler:function(value){

        this.countriesList = value

        this.selectedCountries.forEach(selectedCountry =>{

          if(!this.countriesList.some(country => country.id == selectedCountry.id)){

            this.countriesList.push(selectedCountry)

          }

        });

      },deep:true
    },
    selectedCountries:{
      handler:function(value){
        
        // this.listStates = null;
        // this.selectedStates = null;
        // value = _.cloneDeep(value);
        // value.forEach((element) => {
        //   let hasState = false;
        //   element = _.cloneDeep(element);
        //   element.states.every((state) => {
        //     hasState = true;
        //     return false;
        //   });

        //   if (this.listStates == null || this.selectedStates == null) {
        //     if (hasState) {
        //       let statesList = []
        //       element.states.forEach((state)=>{
        //         if(state.available_cities.length != 0){
        //           statesList.push(state);
        //         }
        //       })
        //       this.listStates = [...element.states]
        //       this.selectedStates = [...statesList]
        //     }
        //   } else {
        //     if (hasState) {
        //       let statesList = []
        //       element.states.forEach((state)=>{
        //         if(state.available_cities.length != 0){
        //           statesList.push(state);
        //         }
        //       })
        //       this.listStates = [this.listStates, ...element.states];
        //       this.selectedStates = [this.selectedStates, ...statesList];
        //     }
        //   }
        // });
        if(value.length > 0){
          
          // this.loadStates();

          this.isTouched = true

        }else{

          this.statesList = []
          this.selectedStates = []
          this.citiesList = []
          this.selectedCities = []

          this.isTouched = false

        }

      },
      deep: true,
    },
    selectedStates:{
      handler:function(value){

        // this.listCities = null;
        // this.selectedCities = null;
        // value = _.cloneDeep(value);
        // // console.log(value, "states");
        // if (this.selectedStates != null && this.listStates != null) {
        //   value.forEach((element) => {
        //     let hasCities = false;
        //     element = _.cloneDeep(element);
        //     element.available_cities.every((city) => {
        //       hasCities = true;
        //       return false;
        //     });
        //     if (this.listCities == null || this.selectedCities == null) {
        //       if (hasCities) {
        //         this.listCities = [...element.available_cities];
        //         this.selectedCities = [...element.available_cities];
        //       }
        //     } else {
        //       if (hasCities) {
        //         this.listCities = this.listCities.concat(
        //           element.available_cities
        //         );
        //         this.selectedCities = this.selectedCities.concat(
        //           element.available_cities
        //         );
        //       }
        //     }
        //   });
        // }
        if(value.length > 0){

          // this.loadCities();

          this.isTouched = true

        }else{

          this.citiesList = []
          // this.selectedCities = null

          this.isTouched = false

        }

      },
      deep: true,
    },
    activeShipping:{
      handler:function(value){

        if(value.id == 2){

          this.rule = {
            id: uuidv4(),
            name: "",
            business_delivery: "",
            weight: [
              {
                id: uuidv4(),
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:'',
              },
              {
                id: uuidv4(),
                from: 1,
                to: '',
                price: "",
                non_cod_price:'',
              },
            ],
          };

        }else if(value.id == 3){

          this.rule = {
            id: uuidv4(),
            name: "",
            business_delivery: "",
            prices: [
              {
                id: uuidv4(),
                from: 0.0,
                to: 1,
                price: "",
                non_cod_price:'',
              },
              {
                id: uuidv4(),
                from: 1,
                to: '',
                price: "",
                non_cod_price:'',
              },
            ],
          };

        }else if(this.activeShipping.id == 4){

          this.rule = {
            id: uuidv4(),
            name: "",
            number:'',
            address: '',
            city: ''
          };

        }else if(this.activeShipping.id == 5){

          this.rule = {
            id: uuidv4(),
            name: "",
            business_delivery:'',
            price: '',
            non_cod_price:"",
          };

        }

        this.shippingRules = [_.cloneDeep(this.rule)];
        
        if(value){

          this.isTouched = true

        }else{

          this.isTouched = false

        }

      },
      deep: true,
    },
    'region_name':{
      handler:function(value){

        if(value){

          this.isTouched = true

        }else{

          this.isTouched = false

        }

      }
    }
  },
};
</script>
<style scoped>
  .multiselect {
    color: black;
  }
</style>